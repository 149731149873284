// Only imports specific lodash functions on the front-end to reduce bundle size
const _ = {
    merge: require('lodash/merge'),
    uniq: require('lodash/uniq'),
    find: require('lodash/find'),
    template: require('lodash/template'),
    forEach: require('lodash/forEach')
};
const cdnPrefix = $('#cdnUrl').val();
const relativeBucketUrlPrefix = $('#relativeBucketUrl').val();
const qi = cdnPrefix.indexOf('?');
// Builds a full image Url
const cdnUrl = (uri, filters) => qi > 0 ? cdnPrefix.substr(0, qi) + 'resize/' + uri + '/' + filters + cdnPrefix.substr(qi) : cdnPrefix + 'resize/' + uri + '/' + filters;
const relativeBucketUrl = (uri) => relativeBucketUrlPrefix  + uri;

//Load templates
const variantColoursTemplate = require('!!raw-loader!./showroom/variant-colour-picker.ejs.html').default;
const variantColorTemplate = require('!!raw-loader!./showroom/variant-color-image.ejs.html').default;

const $modelGallery = $('.model-gallery');

if ($modelGallery.length > 0) {
    $modelGallery.each((i, el) => {
        const $gallery = $(el);
        const $variantsData = $gallery.find('[name^="variants_data"]');
        const $variantPicker = $gallery.find('[name^="variant_picker"]');
        const $variantColours = $gallery.find('.model-gallery__colours');
        const $colourTitle = $gallery.find('#colours');
        const $tabs = $gallery.find('.tab');
        // Get the model data retrieved by the page
        const variantsData = JSON.parse($variantsData.val());

        /**
         * Simple templating function which interpolates the properties of the provided object and template string using
         * the lodash templating function <% %>
         *
         * @param template    The template
         * @param obj       The object containing the data to be inserted
         * @returns {*}
         */
        function _template(template, obj) {
            // imports the forEach function for use in templating
            if (obj) {
                obj._ = { forEach: _.forEach };
                obj.cdnUrl = cdnUrl;
                obj.relativeBucketUrl = relativeBucketUrl;
                obj.cdnPrefix = cdnPrefix;
            }
            return _.template(template)(obj);
        }

        function getVariant(modelId) {
            let activeModel;
            if (modelId) {
                activeModel = _.find(variantsData, { id: modelId });
            } else {
                activeModel = variantsData[0];
            }

            $colourTitle.text(activeModel.colours.length);
            $variantColours.html(_template(variantColoursTemplate, {
                variantId: activeModel.id,
                colours: activeModel.colours,
                sides: activeModel.colours[0].sides
            }));
            let $variantColorImage = $gallery.find('#variant-color-image');
            let $selectSide = $gallery.find('#image_side');
            let $selectColor = $gallery.find('#color_select');
            let activeSide = $gallery.find('#model-gallery_images_' + activeModel.colours[0].sides[0].id).val();
            let $activeColor = $selectColor.find($gallery.find('input[name^=swatches]:checked')).val();
            let currentOption = _.find(activeModel.colours, { id: $activeColor });
            getImage(activeSide, currentOption);

            $selectSide.find('input').on('click', function () {
                activeSide = $selectSide.find($gallery.find('input[name^=images]:checked')).val();
                getImage(activeSide, currentOption);
            });

            $selectColor.find('input').on('click', function () {
                $activeColor = $selectColor.find($gallery.find('input[name^=swatches]:checked')).val();
                currentOption = _.find(activeModel.colours, { id: $activeColor });
                getImage(activeSide, currentOption);
            });

            function getImage(side, colour) {
                let url = cdnUrl(_.find(colour.sides, { id: side }).image, 'fit-in/1000x0/filters:quality(85)');
                $variantColorImage.html(_template(variantColorTemplate, { imageSrc: url }));
                let $colourNameLabel = $gallery.find('#colour_name');
                $colourNameLabel.html(colour.name);
                let $colourDisclaimerLabel = $gallery.find('#colour_disclaimer');
                if ($colourDisclaimerLabel && $colourDisclaimerLabel.length > 0) $colourDisclaimerLabel.html(colour.disclaimer);
            }

        }

        $variantPicker.on('change', function () {
            getVariant($(this).val());
        });

        $tabs.on('click', function () {
            getVariant($(this).data('id'));
        });

        setTimeout(function () {
            getVariant($variantPicker.val());
            $variantPicker.trigger('change');
        })
    });
}